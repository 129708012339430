// import js dependencies

//swiper js
import Swiper from 'swiper/bundle';
//swiper css
import 'swiper/css/bundle';
import 'jquery';
import 'lity';

jQuery(function($) {
	$('#header nav li.menu-item-has-children').click(function(e) {
		if(!$(this).hasClass('open') && $(window).width() < 992) {
			e.preventDefault();
			$(this).parent().find('.open ul').slideUp();
			$(this).parent().find('.open').removeClass('open');
			$(this).addClass('open').find('ul').slideDown();
		}
	});

	$('#header #menu-toggle').click(function() {
		$(this).toggleClass('open');
		$('#header nav .open').removeClass('open');
		$('#header nav').stop().slideToggle();
	});

	$('.block-expanding_list .bordered h3').click(function() {
		$(this).parent().toggleClass('open').find('.wysiwyg').stop().slideToggle(200);
	});

	const swiper = new Swiper('.block-supporter_logos .swiper', {
		speed: 400,
		spaceBetween: 25,
		slidesPerView: 3,
		navigation: {
			nextEl: '.swiper-button-next',
			prevEl: '.swiper-button-prev',
		},
		breakpoints: {
			768: {
				slidesPerView: 4
			},
			992: {
				slidesPerView: 6
			}
		}
	});
});